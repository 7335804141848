exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-available-languages-dubbing-tsx": () => import("./../../../src/pages/available-languages/dubbing.tsx" /* webpackChunkName: "component---src-pages-available-languages-dubbing-tsx" */),
  "component---src-pages-available-languages-index-tsx": () => import("./../../../src/pages/available-languages/index.tsx" /* webpackChunkName: "component---src-pages-available-languages-index-tsx" */),
  "component---src-pages-available-languages-transcription-tsx": () => import("./../../../src/pages/available-languages/transcription.tsx" /* webpackChunkName: "component---src-pages-available-languages-transcription-tsx" */),
  "component---src-pages-available-languages-translation-tsx": () => import("./../../../src/pages/available-languages/translation.tsx" /* webpackChunkName: "component---src-pages-available-languages-translation-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-enterprise-education-tsx": () => import("./../../../src/pages/enterprise/education.tsx" /* webpackChunkName: "component---src-pages-enterprise-education-tsx" */),
  "component---src-pages-enterprise-healthcare-tsx": () => import("./../../../src/pages/enterprise/healthcare.tsx" /* webpackChunkName: "component---src-pages-enterprise-healthcare-tsx" */),
  "component---src-pages-enterprise-index-tsx": () => import("./../../../src/pages/enterprise/index.tsx" /* webpackChunkName: "component---src-pages-enterprise-index-tsx" */),
  "component---src-pages-enterprise-safety-tsx": () => import("./../../../src/pages/enterprise/safety.tsx" /* webpackChunkName: "component---src-pages-enterprise-safety-tsx" */),
  "component---src-pages-faqs-tsx": () => import("./../../../src/pages/faqs.tsx" /* webpackChunkName: "component---src-pages-faqs-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-pages-thank-you-tsx": () => import("./../../../src/pages/thank-you.tsx" /* webpackChunkName: "component---src-pages-thank-you-tsx" */),
  "component---src-templates-blog-category-tsx": () => import("./../../../src/templates/blog-category.tsx" /* webpackChunkName: "component---src-templates-blog-category-tsx" */),
  "component---src-templates-blog-single-tsx": () => import("./../../../src/templates/blog-single.tsx" /* webpackChunkName: "component---src-templates-blog-single-tsx" */),
  "component---src-templates-custom-page-tsx": () => import("./../../../src/templates/custom-page.tsx" /* webpackChunkName: "component---src-templates-custom-page-tsx" */)
}

